import {
  AppConfigContext,
  getApiUrlForPath,
} from "@providers/app-config-provider";
import {useContext} from "react";
import {DownloadDatabaseButton} from "./base/DownloadDatabaseButton";

export type CpuProfileDownloadButtonProps = {
  profileID: number;
};

export function CpuProfileDownloadButton({
  profileID,
}: CpuProfileDownloadButtonProps) {
  const appConfig = useContext(AppConfigContext);
  const url = getApiUrlForPath(
    appConfig.APIBaseURL,
    `/cpu-profile/${profileID}`,
  );
  const tooltip = "Download a SQLite database with this profile's data";
  const fallbackFilename = `cpuprofile-${profileID}.sqlite`;
  return (
    <DownloadDatabaseButton
      url={url}
      tooltip={tooltip}
      fallbackFilename={fallbackFilename}
    />
  );
}
