import React from "react";
import {useAgentReports} from "../../../providers/agent-reports-provider.tsx";
import {Box, Typography} from "@mui/material";
import {Link} from "react-router-dom";

type Props = {
  isOpen: boolean;
};

// AgentsSummary renders the number of connected agents at the bottom of the
// menu drawer.
export const AgentsSummary: React.FC<Props> = ({isOpen}) => {
  const agentsReports = useAgentReports();
  const numAgents = agentsReports?.Report?.Reports?.length ?? 0;

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: (theme) => theme.palette.background.sidebarFooter,
      }}
    >
      <Link
        to="/agents"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textDecoration: "none",
        }}
      >
        <Typography variant="body1" color="textPrimary">
          {numAgents}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {isOpen
            ? `${numAgents === 1 ? "Agent connected" : "Agents connected"}`
            : `${numAgents === 1 ? "Connected" : "Connected"}`}
        </Typography>
      </Link>
    </Box>
  );
};
