import {useQuery} from "@apollo/client";
import React, {createContext, PropsWithChildren, useContext} from "react";
import {GET_LABEL_RULES} from "src/pages/Agents/gqlHelper.ts";
import {Label} from "src/util/labels.ts";
import {gql} from "../__generated__";
import {GetAgentReportsQuery, LabelRule} from "../__generated__/graphql.ts";
import {AppConfigContext} from "./app-config-provider.tsx";

const GET_AGENT_REPORTS = gql(/* GraphQL */ `
  query GetAgentReports {
    getAgentReports {
      DataVersion
      ExVersion
      Reports {
        AgentID
        Environment
        Hostname
        IPAddresses
        AgentVersion
        KernelVersion
        Processes {
          ProcessToken
          PID
          Labels {
            Label
            Value
          }
          Binary {
            __typename
            ... on UnknownBinaryInfo {
              Hash
              SuggestedName
            }
            ... on Binary {
              id
              userName
            }
          }
          PProfAddress
        }
      }
    }
  }
`);

export type AgentReportsResult = GetAgentReportsQuery["getAgentReports"];

export type AgentReport = {
  NoAgentsReporting: boolean;
  NoProgramsConfigured: boolean;
  Report: AgentReportsResult;
};

export const AgentReportsContext = createContext<AgentReport | undefined>(
  // The provided value is undefined until the queries complete.
  undefined,
);

export function useAgentReports(): AgentReport | undefined {
  return useContext(AgentReportsContext);
}

export const AgentReportsProvider: React.FunctionComponent<
  PropsWithChildren<unknown>
> = ({children}) => {
  const appCfg = useContext(AppConfigContext);

  // If authentication is enabled but there is no logged-in user, skip the
  // query.
  let skip = false;
  if (appCfg.AuthEnabled && !appCfg.User) {
    skip = true;
  }
  const {data: reportRes} = useQuery(GET_AGENT_REPORTS, {
    // Read the agents' state every so often. When the results change, all the
    // components using useContext(AgentReportContext) will be re-rendered. Note
    // that when the query results don't change, no re-rendering happens.
    //
    // Switching `skip` from false to true does not seem to have any effect, so
    // we also set pollInterval to a large number when the query is skipped. See
    // https://github.com/apollographql/apollo-client/issues/11120
    pollInterval: !skip ? appCfg.AgentsPollIntervalMillis : 1000000000,
    fetchPolicy: "no-cache",
    skip: skip,
  });
  // Read the label rules to check if the program rules are configured.
  let {data: labelRulesRes} = useQuery(GET_LABEL_RULES, {
    skip: skip,
  });
  const programRulesConfigured = labelRulesRes?.getLabelRules.some(
    (rule: LabelRule) => rule.label == Label.Program,
  );
  let ctxValue: AgentReport | undefined = undefined;
  if (reportRes && labelRulesRes) {
    ctxValue = {
      NoAgentsReporting: reportRes.getAgentReports.Reports.length == 0,
      NoProgramsConfigured: !programRulesConfigured,
      Report: reportRes.getAgentReports,
    };
  }

  return (
    <AgentReportsContext.Provider value={ctxValue}>
      {children}
    </AgentReportsContext.Provider>
  );
};
