import {Box, FormControl, MenuItem, Select, Stack} from "@mui/material";
import {gql} from "src/__generated__/gql";
import {
  FilteringOptionType,
  FilteringSuggestion,
  GoroutineStatusFilter,
  StacksFilter,
} from "src/__generated__/graphql";
import Filter from "@components/filter";
import {ActiveFilterOption} from "@components/filter/Filter.tsx";
import {ScoredSuggestion} from "@components/filter/util.tsx";

export const GET_FILTERING_OPTS = gql(/* GraphQL */ `
  query GetFilteringOptions($snapshotID: Int!) {
    filteringOptions(snapshotID: $snapshotID) {
      Category
      Package
      TypeName
      FuncName {
        Package
        Type
        Name
        QualifiedName
      }
      VarExpr
      BinaryID
      BinaryName
      ProgramName
      GoroutineStatus
    }
  }
`);

export interface ProcessForSelector {
  processID: number;
  processFriendlyName: string;
}

export type FilterBarProps = {
  processes: ProcessForSelector[];
  onInputChange: (value: string) => void;
  filteringSuggestions: FilteringSuggestion[];
  filters: StacksFilter[];
  setFilters: (filters: StacksFilter[]) => void;
  focusedNodes: number[] | undefined;
  setFocusedNodes: (focusedNodes: number[] | undefined) => void;
};

// GoroutineFilterBar renders filtering for goroutines in a snapshot or CPU
// profile. It combines a process selector with a Filter.
export default function GoroutineFilterBar(
  props: FilterBarProps,
): React.JSX.Element {
  const showAllProcsOption = props.processes?.length > 1;
  const firstSnapshot = props.processes?.[0];
  const processFilter = props.filters.find(
    (f) => f.Type == FilteringOptionType.ProcessSnapshot,
  );
  let procValue: number | undefined;
  if (processFilter) {
    procValue = processFilter.ProcessID!;
  } else {
    procValue = showAllProcsOption
      ? 0
      : firstSnapshot != undefined
        ? firstSnapshot.processID
        : undefined;
  }

  const onSelectedProcessChanged = (event) => {
    // Remove the existing filter on snapshot, if it exists.
    const newFilters = props.filters.filter(
      (f) => f.Type != FilteringOptionType.ProcessSnapshot,
    );
    // Add a new function filter, unless the "all" option was selected.
    const selectedProcessID = event.target.value as number;
    if (selectedProcessID != 0) {
      newFilters.push({
        Type: FilteringOptionType.ProcessSnapshot,
        ProcessID: selectedProcessID,
      });
    }
    props.setFilters(newFilters);
  };

  // Build separate lists of all the values that should be in the filter.
  // First are the filter, then the suggestions.
  const filterOptions: ActiveFilterOption[] = props.filters
    .filter((filter) => filter.Type != FilteringOptionType.ProcessSnapshot)
    .map((filter) => ({
      type: "synthetic",
      filter,
    }));

  // Add the focused nodes entry
  if (props.focusedNodes && props.focusedNodes?.length > 1) {
    filterOptions.push({
      type: "focusedNodes",
      focusedNodes: props.focusedNodes,
    });
  }

  // Convert the filtering suggestions to scored suggestions.
  const suggestionOptions: ScoredSuggestion[] = (
    props.filteringSuggestions || []
  ).map(
    (filteringSuggestion: FilteringSuggestion): ScoredSuggestion =>
      new ScoredSuggestion(filteringSuggestion),
  );

  const extraSuggestions: ScoredSuggestion[] = Object.values(
    GoroutineStatusFilter,
  ).map(
    (status: GoroutineStatusFilter) =>
      new ScoredSuggestion({
        Category: FilteringOptionType.GoroutineStatus,
        GoroutineStatus: status,
        BinaryID: null,
        BinaryName: null,
        FuncName: null,
        Package: null,
        ProgramName: null,
        TypeName: null,
        VarExpr: null,
      }),
  );

  return (
    <Stack direction="row" flexGrow={1} height={"51px"}>
      <FormControl sx={{mr: 1, minWidth: 120, height: "100%"}} margin="none">
        <Select
          color="secondary"
          value={procValue}
          onChange={onSelectedProcessChanged}
          sx={{height: "100%"}}
        >
          {showAllProcsOption && (
            <MenuItem key={0} value={0}>
              All {props.processes?.length} processes.
            </MenuItem>
          )}
          {props.processes?.map((snapshot) => (
            <MenuItem key={snapshot.processID} value={snapshot.processID}>
              {snapshot.processFriendlyName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{flexGrow: 1, height: "100%"}}>
        <Filter
          suggestions={[...suggestionOptions, ...extraSuggestions]}
          values={filterOptions}
          onChange={(values: ActiveFilterOption[]) => {
            const filters: StacksFilter[] = values
              .filter((f) => f.type == "synthetic")
              .map((f) => f.filter);
            // If there is a snapshot filter coming from the dropdown, add it.
            if (processFilter) {
              filters.push(processFilter);
            }
            props.setFilters(filters);

            // If the focused nodes filter was removed, reset the focused nodes.
            const shouldHaveFocusedNodes =
              values.find((value) => value.type == "focusedNodes") != undefined;
            if (
              !shouldHaveFocusedNodes &&
              props.focusedNodes &&
              props.focusedNodes.length > 1
            ) {
              props.setFocusedNodes(undefined);
            }
          }}
          onInputChange={(value: string) => props.onInputChange(value)}
        />
      </div>
    </Stack>
  );
}
