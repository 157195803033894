import React, {Key, useRef, useState} from "react";
import {
  FilteringOptionType,
  FilteringSuggestion,
  GoroutineStatusFilter,
  StacksFilter,
} from "@graphql/graphql.ts";
import {useProcessResolver} from "@providers/processResolverProvider.tsx";
import {
  AutocompleteInputChangeReason,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import {
  categoryPrefixes,
  funcMatch,
  highlightedString,
  highlightPart,
  matchFunctionName,
  matchPackage,
  matchTypeName,
  matchVariable,
  queryPrefixToSuggestionType,
  ScoredSuggestion,
  suggestionCategoriesInfo,
  suggestionCategoryStrings,
  suggestionCategoryToString,
  typeMatch,
  varMatch,
} from "./util.tsx";
import {HelpCircle} from "@components/HelpCircle.tsx";
import {exhaustiveCheck} from "@util/util.ts";
import _ from "lodash";
import {ProcessResolver} from "@util/process-resolver.ts";

interface FilterProps {
  // The values in the filter are the non-suggestions among the options.
  suggestions: ScoredSuggestion[];
  values: ActiveFilterOption[];
  onChange: (val: ActiveFilterOption[]) => void;
  onInputChange: (value: string) => void; // The partial text that isn't matched
}

// Filter renders the filters text box. It contains autocomplete suggestions for
// possible filters, and it renders the current filters as chips.
export default function Filter(props: FilterProps): React.JSX.Element {
  const [_inputValue, setInputValue] = useState("");

  // The values we want to show are all non-suggestion options.
  // We memoize `values` because we pass it to the Autocomplete, and it doesn't
  // like it when the array reference changes.
  // https://github.com/mui/material-ui/issues/32425#issuecomment-1144892214
  const valuesRef = useRef([] as ActiveFilterOption[]);
  const values = valuesRef.current;
  // Clear the array; we'll push the values again below.
  values.length = 0;
  props.values.forEach((value) => {
    values.push(value);
  });

  // The Filter component can show a dialog for editing a variable filter.
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState(
    undefined as VariableDialogState | undefined,
  );
  const handleDialogClose = () => {
    setDialogValue(undefined);
    setDialogOpen(false);
    props.onChange(values);
  };
  const handleDialogSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const option: ActiveFilterOption = values[dialogValue!.valueIdx];

    if (option.type != "synthetic") {
      // Can't be edited for now
      handleDialogClose();
      return;
    }
    const oldVarValue: string = option.filter.VarValue!;
    if (oldVarValue == dialogValue!.variableValue) {
      // No change.
      handleDialogClose();
    }

    // Replace the filter in the values array with the updated one.
    if (dialogValue!.variableValue == "") {
      // TODO(mihai) we just want to remove it.
      //  How can you support actually querying for empty string though?
      //  I'd expect some explicit differentiation between removal and
      //  empty string.
    } else {
      values[dialogValue!.valueIdx] = {
        type: "synthetic",
        filter: {
          Type: FilteringOptionType.Variable,
          Package: "",
          TypeName: "",
          FuncName: option.filter.FuncName!,
          VarExpr: option.filter.VarExpr ?? undefined,
          VarValue: dialogValue!.variableValue,
        },
      };
    }
    handleDialogClose();
  };

  const processResolver = useProcessResolver();

  // Used to render the chips in the Autocomplete text box and to generate the
  // keys for the list elements in the autocomplete dropdown.
  function getOptionLabel(
    f: AutocompleteOption,
    processResolver: ProcessResolver,
  ): string {
    if (f instanceof ScoredSuggestion) {
      return f.getLabel();
    }

    if (f.type == "focusedNodes") {
      return `${f.focusedNodes.length} functions selected`;
    }

    return getStacksFilterLabel(f.filter, processResolver);
  }

  function getStacksFilterLabel(
    filter: StacksFilter,
    processResolver: ProcessResolver,
  ): string {
    switch (filter.Type) {
      case FilteringOptionType.Package:
        return `pkg: ${filter.Package!}`;
      case FilteringOptionType.PackagePrefix:
        return `pkg: ${filter.Package!}/...`;
      case FilteringOptionType.Type:
        return `type: ${filter.TypeName!}`;
      case FilteringOptionType.Function:
        return `function: ${filter.FuncName!}`;
      case FilteringOptionType.Variable:
        if (filter.VarValue) {
          return (
            "var:" +
            filter.FuncName! +
            " - " +
            filter.VarExpr +
            "=" +
            filter.VarValue
          );
        } else {
          return "var: " + filter.FuncName! + " - " + filter.VarExpr;
        }
      case FilteringOptionType.Goroutine: {
        const proc = processResolver.resolveProcessIDOrThrow(filter.ProcessID!);
        const procName = proc.FriendlyName;
        return `goroutine: ${procName} : ${filter.GoroutineID!}`;
      }
      case FilteringOptionType.BinaryId:
        return "binary: " + processResolver.resolveBinaryName(filter.BinaryID!);
      case FilteringOptionType.ProgramName:
        return "program: " + filter.ProgramName;
      case FilteringOptionType.StackPrefix:
        return "stack prefix: ...->" + filter.StackPrefixLastFunc!;
      case FilteringOptionType.GoroutineStatus:
        return (
          "status: " + goroutineStatusFilterString(filter.GoroutineStatus!)
        );
      default:
        return "unknown";
    }
  }

  // NOTE: We set the `open` prop on the Dialog below, but sometimes that seems
  // to be ignored for some reason, so we also only conditionally include the
  // dialog in the tree below, based on the same `dialogOpen` state.
  const dialog = (
    <Dialog open={dialogOpen} onClose={handleDialogClose}>
      <form onSubmit={handleDialogSubmit}>
        <DialogTitle>Variable options</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Find only variables containing the following value
          </DialogContentText>
          <TextField
            margin="dense"
            id="name"
            value={dialogValue ? dialogValue.variableValue : ""}
            onChange={(event) => {
              setDialogValue({
                ...dialogValue!,
                variableValue: event.target.value,
              });
            }}
            label="variable value"
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  // The handler for clicking on a chip representing a filter. When clicking on filters
  // referring to variables, we open a dialog for editing the variable value.
  const onChipClick = (idx: number) => {
    const option = values[idx];

    // For each type, either load the correct value to return.
    if (option.type != "synthetic") {
      return;
    }
    if (option.filter.Type != FilteringOptionType.Variable) {
      return;
    }

    setDialogValue({
      valueIdx: idx,
      variableValue: option.filter.VarValue ?? "",
    });
    setDialogOpen(true);
  };

  return (
    <>
      <Autocomplete
        multiple
        id="tags-filled"
        options={props.suggestions}
        value={values}
        // Make the enter key select the first suggestion in the dropdown.
        autoHighlight={true}
        // Do not wipe the input (the last thing typed which was not yet made
        // part of a chip) when the focus is lost.
        clearOnBlur={false}
        // Make the Home/End keys move the cursor in the textbox instead of
        // selecting the first/last suggestions in the dropdown.
        handleHomeEndKeys={false}
        renderTags={(value: AutocompleteOption[], getTagProps) => {
          return value.map((option: AutocompleteOption, index: number) => {
            // The "key" property needs to be passed explicitly. See
            // https://github.com/mui/material-ui/issues/39833.
            const {key, ...props} = getTagProps({index});
            return (
              <Chip
                variant="outlined"
                label={getOptionLabel(option, processResolver)}
                key={key}
                onClick={() => onChipClick(index)}
                {...props}
              />
            );
          });
        }}
        renderInput={(params) => {
          return (
            <TextField
              color="secondary"
              {...params}
              placeholder="Filters"
              sx={{
                "& .MuiInputBase-root": {flexGrow: 1, height: "auto"},
                flexGrow: 1,
              }}
            />
          );
        }}
        onChange={(_event, newValues: AutocompleteOption[]) => {
          const values = AutocompleteOptionsToFilters(newValues);
          props.onChange(values);
        }}
        onInputChange={(
          _event: React.SyntheticEvent,
          value: string,
          _reason: AutocompleteInputChangeReason,
        ) => {
          setInputValue(value);
          props.onInputChange(value);
        }}
        getOptionLabel={(option: AutocompleteOption) =>
          getOptionLabel(option, processResolver)
        }
        filterOptions={(options, state) =>
          filterSuggestions(
            options,
            state.inputValue,
            100 /* limit - otherwise rendering too many options is slow */,
          )
        }
        renderOption={(props, option: AutocompleteOption) =>
          renderSuggestion(option, props)
        }
        sx={{height: "100%", display: "flex", flexGrow: 1}}
      />
      {dialogOpen && dialog}
    </>
  );
}

// AutocompleteOption is an element of the value array of the Autocomplete
// component. Autocomplete options are either actual autocomplete suggestions
// (package/type/function names, captured variable names, etc.) or "synthetic"
// options that represent selected filters. Such synthetic filters are part of
// the Autocomplete values in order to get them rendered as chips inside the
// Autocomplete textbox.
export type AutocompleteOption =
  | ScoredSuggestion
  | SelectedFilterOption
  | SpecialFocusedNodeOption;

export type ActiveFilterOption =
  | SelectedFilterOption
  | SpecialFocusedNodeOption;
type SelectedFilterOption = {
  type: "synthetic";
  filter: StacksFilter;
};
// TODO(mihai) Still considering how to generalize filters here
type SpecialFocusedNodeOption = {
  type: "focusedNodes";
  focusedNodes: number[];
};

// VariableDialogState represents the state of the modal dialog for editing a
// variable filter.
type VariableDialogState = {
  // The index of the filter being edited within the props.value array.
  valueIdx: number;
  // The current filter on the variable's value. '' if the value is not being filtered.
  variableValue: string;
};

// AutocompleteOptionsToFilters converts AutocompleteOptions to StacksFilters.
// Used for passing filter updates to the parent component.
export function AutocompleteOptionsToFilters(
  options: readonly AutocompleteOption[],
): ActiveFilterOption[] {
  let filters: ActiveFilterOption[] = options.map(
    (option: AutocompleteOption) => {
      if (!(option instanceof ScoredSuggestion)) {
        // Non-suggestions are preserved
        return option;
      }

      return {
        type: "synthetic",
        filter: scoredSuggestionToFilter(option),
      };
    },
  );

  filters = keepOnlyLastStackFilter(filters, FilteringOptionType.BinaryId);
  filters = keepOnlyLastStackFilter(filters, FilteringOptionType.ProgramName);

  return filters;
}

// Remove all but the last StackFilter of this type
function keepOnlyLastStackFilter(
  values: ActiveFilterOption[],
  type: FilteringOptionType,
): ActiveFilterOption[] {
  const lastMatchingEntry = values
    .filter((value) => value.type === "synthetic" && value.filter.Type === type)
    .pop();

  return values.filter((value) => {
    if (value.type != "synthetic") {
      return true;
    }
    return value.filter.Type != type || value == lastMatchingEntry;
  });
}

function scoredSuggestionToFilter(
  scoredSuggestion: ScoredSuggestion,
): StacksFilter {
  const filteringSuggestion = scoredSuggestion.suggestion;
  // Handle the special case of a variable filter with a value. For such a
  // filter we need to fill in the variable value manually.
  if (
    filteringSuggestion.Category == FilteringOptionType.Variable &&
    scoredSuggestion.match != undefined
  ) {
    const varMatch = scoredSuggestion.match as varMatch;
    const varValue = varMatch.varValue.raw();
    if (varValue != "") {
      return {
        Type: filteringSuggestion.Category,
        FuncName: filteringSuggestion.FuncName?.QualifiedName,
        VarExpr: filteringSuggestion.VarExpr!,
        VarValue: varValue,
      };
    }
  }
  return filteringSuggestionToFilter(filteringSuggestion);
}

function filteringSuggestionToFilter(s: FilteringSuggestion): StacksFilter {
  switch (s.Category) {
    case FilteringOptionType.Function:
      return {
        Type: s.Category,
        Package: s.FuncName!.Package,
        TypeName: s.FuncName!.Type,
        FuncName: s.FuncName!.QualifiedName,
      };
    case FilteringOptionType.Package:
    case FilteringOptionType.PackagePrefix:
      return {
        Type: s.Category,
        Package: s.Package!,
      };
    case FilteringOptionType.Type:
      return {
        Type: s.Category,
        Package: s.Package!,
        TypeName: s.TypeName!,
      };
    case FilteringOptionType.Variable:
      return {
        Type: s.Category,
        FuncName: s.FuncName?.QualifiedName,
        VarExpr: s.VarExpr!,
      };
    case FilteringOptionType.BinaryId: {
      return {
        Type: s.Category,
        BinaryID: s.BinaryID!,
      };
    }
    case FilteringOptionType.ProgramName: {
      return {
        Type: s.Category,
        ProgramName: s.ProgramName!,
      };
    }
    case FilteringOptionType.GoroutineStatus: {
      return {
        Type: s.Category,
        GoroutineStatus: s.GoroutineStatus!,
      };
    }
    default:
      throw new Error(`unsupported filtering option category: ${s.Category}`);
  }
}

// filterSuggestions returns the list of suggestions that match the given input,
// sorted by match score. Due to the <Autocomplete> interface, both the input
// and the output need to be of the same type. The input represents the
// autocomplete options coming in from the props, and the output will be actual
// scored suggestions.
//
// If limit is specified, only up to that many suggestions will be returned (the
// highest scoring ones).
function filterSuggestions(
  options: AutocompleteOption[],
  query: string,
  limit?: number,
): AutocompleteOption[] {
  // Filter out synthetic options: they correspond to chips for other filters,
  // not the filter that the inputValue will turn into. Note however that
  // filterSuggestions might return new synthetic options if the query contains
  // a variable value.
  const suggestions = options.filter(
    (option) => option instanceof ScoredSuggestion,
  );

  // Smart case: if the input is all lower case, then we'll ignore the case of
  // the suggestions.
  const caseSensitive = query.toLowerCase() != query;

  // Deal with the prefix of the query representing a filter on the type of
  // suggestion the user wants. If the query explicitly asks for a certain type
  // of suggestion (i.e. query starts with something like "var:"), we remember
  // it and strip that prefix from the query. Otherwise, if the query in its
  // entirety is a prefix of a suggestion type (e.g. query is "va" which is a
  // prefix of "var:"), then we record that fact but don't strip the prefix.
  let categoryInfo: suggestionCategoriesInfo;
  {
    let explicitCategories: FilteringOptionType[] | undefined;
    let typeQuery = "",
      rest = "";
    // Does the query explicitly ask for a category?
    const colonIndex = query.indexOf(":");
    if (colonIndex != -1) {
      [typeQuery, rest] = [
        query.substring(0, colonIndex),
        query.substring(colonIndex + 1),
      ];
      explicitCategories = queryPrefixToSuggestionType.get(typeQuery);
    }

    if (explicitCategories) {
      // Strip the category from the query.
      query = rest;

      // The category name for the selected categories will be entirely highlighted.
      categoryInfo = new suggestionCategoriesInfo(explicitCategories, true);
      const m = new Map(categoryInfo.categoryLabels);
      for (const cat of explicitCategories) {
        m.set(
          cat,
          highlightedString.allHighlighted(suggestionCategoryToString(cat)),
        );
      }
      categoryInfo.categoryLabels = m;
    } else {
      // Does the query match a category prefix?
      const categories = categoryPrefixes.get(query);
      if (categories != undefined) {
        categoryInfo = new suggestionCategoriesInfo(categories, false);
        const m = new Map(categoryInfo.categoryLabels);
        // Highlight the prefixes of the categories that match the query.
        for (const cat of categories) {
          m.set(
            cat,
            highlightPart(suggestionCategoryStrings.get(cat)!, query, false),
          );
        }
        categoryInfo.categoryLabels = m;
      } else {
        categoryInfo = new suggestionCategoriesInfo([], false);
      }
    }
  }

  const pkgPrefixSuggestions = new Set<string>();
  let matches: ScoredSuggestion[] = suggestions
    .map((suggestion) =>
      scoreCandidate(
        suggestion.suggestion,
        query,
        categoryInfo,
        caseSensitive,
        pkgPrefixSuggestions,
      ),
    )
    .flat();

  // Sort the matches by score (highest scores first). We use a stable sort so
  // that new suggestions generated by scoreCandidate() above stay next to the
  // original suggestion that they were derived from.
  matches = _.sortBy(matches, (m) => -m.score);
  return matches.slice(0, limit);
}

function renderSuggestion(
  suggestion: AutocompleteOption,
  props: React.HTMLAttributes<HTMLLIElement> & {key: Key},
): React.JSX.Element {
  if (!(suggestion instanceof ScoredSuggestion)) {
    throw new Error("unexpected synthetic option");
  }

  let matchJSX = <></>;
  switch (suggestion.suggestion.Category) {
    case FilteringOptionType.Type:
      matchJSX = (suggestion.match! as typeMatch).html();
      break;
    case FilteringOptionType.Function:
      matchJSX = (suggestion.match! as funcMatch).html();
      break;
    case FilteringOptionType.Variable:
      matchJSX = (suggestion.match as varMatch).html();
      break;

    case FilteringOptionType.GoroutineStatus: {
      let tip: string | undefined = undefined;
      switch (suggestion.suggestion.GoroutineStatus!) {
        case GoroutineStatusFilter.Running:
          tip = "The goroutine is running";
          break;
        case GoroutineStatusFilter.Blocked:
          tip = "The goroutine is not running";
          break;
        case GoroutineStatusFilter.RunningOrRunnable:
          tip = "The goroutine is running or runnable";
          break;
      }
      const tipJSX = tip ? <HelpCircle tip={tip} /> : <></>;
      matchJSX = (
        <Stack direction="row" justifyContent={"center"}>
          <div>{(suggestion.match as highlightedString).html()}</div>
          {tipJSX}
        </Stack>
      );
      break;
    }
    case FilteringOptionType.ProgramName:
    case FilteringOptionType.BinaryId:
    case FilteringOptionType.Package:
    case FilteringOptionType.PackagePrefix:
      matchJSX = (suggestion.match as highlightedString).html();
      break;
    default:
      throw new Error(
        "unexpected suggestion type " + suggestion.suggestion.Category,
      );
  }

  // The "key" property needs to be passed explicitly. See
  // https://github.com/mui/material-ui/issues/39833.
  const {key, ...optionProps} = props;
  return (
    <li key={key} {...optionProps}>
      <Stack direction={"row"} alignItems={"center"} flexGrow={1}>
        <span className="text-muted" style={{marginRight: 5, fontSize: 10}}>
          {suggestion.suggestionCategory.html()}
        </span>
        {matchJSX}
      </Stack>
    </li>
  );
}

// score a query against a suggestion. Higher score means better match. The
// function possibly returns more than a single suggestion in case the
// FilteringSuggestion is expanded to multiple suggestions (e.g. a PACKAGE
// suggestion might be expanded to a couple of PACKAGE_PREFIX suggestions). If
// the suggestion doesn't match the query, an empty array is returned.
function scoreCandidate(
  f: FilteringSuggestion,
  query: string,
  categoriesInfo: suggestionCategoriesInfo,
  caseSensitive: boolean,
  pkgPrefixSuggestions: Set<string>,
): ScoredSuggestion[] {
  const res = new ScoredSuggestion(f);

  // Deal with the suggestion category in relation to categoriesInfo.
  if (categoriesInfo.explicitCategories) {
    // If the query is explicitly asking for a certain category, we'll only
    // consider suggestions of that category.
    if (!categoriesInfo.suggestionCategories.includes(f.Category)) {
      return [];
    } else {
      res.score = 1;
    }
  } else {
    // If the query starts with a prefix of the suggestion's type, we make
    // sure the score is not zero. So if the query is, say, "va", all
    // variables will be considered matches. Similarly, if the whole query is,
    // say, "var:", all variables get a non-zero score.
    if (categoriesInfo.suggestionCategories.includes(f.Category)) {
      res.score = 1;
    }
  }
  res.suggestionCategory = categoriesInfo.categoryLabels.get(f.Category)!;

  switch (f.Category) {
    case FilteringOptionType.Package: {
      return matchPackage(
        f.Package!,
        query,
        res.score,
        categoriesInfo,
        pkgPrefixSuggestions,
      );
    }
    case FilteringOptionType.Type: {
      const [match, score] = matchTypeName(f.Package!, f.TypeName!, query);
      res.match = match;
      res.score += score;
      break;
    }
    case FilteringOptionType.Function: {
      const [match, funcScore] = matchFunctionName(f.FuncName!, query);
      res.match = match;
      res.score += funcScore;
      break;
    }
    case FilteringOptionType.Variable: {
      const [match, varScore] = matchVariable(f.FuncName!, f.VarExpr!, query);
      res.match = match;
      res.score += varScore;
      break;
    }
    case FilteringOptionType.BinaryId:
      res.match = highlightPart(f.BinaryName!, query, caseSensitive);
      if (res.match.hasHighlight()) {
        res.score += 10;
      }
      break;
    case FilteringOptionType.ProgramName:
      res.match = highlightPart(f.ProgramName!, query, caseSensitive);
      if (res.match.hasHighlight()) {
        res.score += 10;
      }
      break;
    case FilteringOptionType.GoroutineStatus:
      res.match = highlightPart(
        goroutineStatusFilterString(f.GoroutineStatus!),
        query,
        false,
      );
      if (res.match.hasHighlight()) {
        res.score += 10;
      }
      break;
    default:
      throw new Error(`unsupported filtering option category: ${f.Category}`);
  }

  if (res.score == 0) {
    return [];
  }

  return [res];
}

function goroutineStatusFilterString(status: GoroutineStatusFilter): string {
  switch (status) {
    case GoroutineStatusFilter.Running:
      return "Running";
    case GoroutineStatusFilter.RunningOrRunnable:
      return "Running or Runnable";
    case GoroutineStatusFilter.Blocked:
      return "Blocked";
    default:
      exhaustiveCheck(status);
  }
}
